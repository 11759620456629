import { getCurrentRouteName, getRoutePath } from '$lib/utils/navigation/navigation';
import { error } from '@sveltejs/kit';
import { translations } from '$lib/i18n/translations';

export function notFoundError(pathname: string) {
	const routeName = getCurrentRouteName(pathname);

	error(404, {
		message: translations.global.errorPageNotFoundMessage,
		...getListingAndMessage(routeName)
	});
}

type ListingMessageType = {
	redirectPath: string;
	redirectLabel: string;
	pageMessage: string;
};

export function getListingAndMessage(routeName: string | undefined): ListingMessageType {
	let redirectPath = getRoutePath('dashboard');
	let redirectLabel = translations.global.backToDashboard;
	switch (routeName) {
		case 'contract_view':
		case 'contract':
			redirectPath = getRoutePath('contract');
			redirectLabel = translations.global.backToContractList;
			break;
		case 'invoice_view':
			redirectPath = getRoutePath('invoice');
			redirectLabel = translations.global.backToInvoiceList;
			break;
		default:
			break;
	}

	return {
		redirectPath,
		redirectLabel,
		pageMessage: translations.global.errorPageNotFoundMessage
	};
}
