import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/(dashboard)": [4,[2]],
		"/account": [19],
		"/(listing)/alert": [5,[3]],
		"/(listing)/collaborator": [6,[3]],
		"/(listing)/contract": [7,[3]],
		"/(listing)/contract/validation/[id=sqlId]": [9,[3]],
		"/(listing)/contract/[id=sqlId]": [8,[3]],
		"/equipment/[slug=both]": [20],
		"/(listing)/fleet": [10,[3]],
		"/fleet/[id=sqlId]": [21],
		"/(listing)/invoice": [11,[3]],
		"/(listing)/invoice/[id=sqlId]": [12,[3]],
		"/login": [22],
		"/(listing)/message": [13,[3]],
		"/message/[id=sqlId]": [23],
		"/register": [24],
		"/(listing)/rental_fleet": [14,[3]],
		"/(listing)/sale": [15,[3]],
		"/(listing)/sale/[id=sqlId]": [16,[3]],
		"/sentry": [25],
		"/(listing)/service": [17,[3]],
		"/(listing)/service/[id=sqlId]": [18,[3]],
		"/wk/[wk]": [26]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';